.pointer{
    cursor: pointer;
}

main{
padding: 0 !important;
}

.budget-actual-row{
  position: relative;
  height: 28px;
}
.budget-actual-row *{
  position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

/* Chrome, Safari & newer versions of Opera */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent; /* Make it invisible by default */
  transition: background-color 0.3s;
  transition-delay: 0.5s; /* Add this line for delay */
}
.shadowStylePaper {
  box-shadow: 0px 10px 15px rgba(0,0,0,0.06);
  border-radius: 8px;
  border: 1px solid #e3e3e3;
}
.settings-card{
  gap: 2rem;
  padding: 2rem 2rem;
  border-radius: 8px;
  width: 100%;
} 
.settings-card-ring{
  gap: 2rem;
  padding: 0rem 2rem;
  border-radius: 8px;
  width: 100%;
} 
.settings-panel {
min-width: 720px;
}
.settings-label{
  min-width: 100px;
  }

  

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make it invisible by default */
  border-radius: 4px;
  transition: background-color 0.3s;
  transition-delay: 0.5s; /* Add this line for delay */
}

/* For Webkit browsers (Chrome, Safari) on hover */
*:hover::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  transition-delay: 0s; /* Reset to 0s on hover */
}

*:hover::-webkit-scrollbar {
  background-color: #dddddd;
  transition-delay: 0s; /* Reset to 0s on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; /* Make it invisible by default */
  transition: scrollbar-color 1s;
  transition-delay: 0.5s; /* Delay */
}

/* Show scrollbar on hover for Firefox */
*:hover {
  scrollbar-color: #a6a6a6 #dddddd;
  transition-delay: 0s; /* Reset to 0s on hover */
}
.MuiOtpInput-Box{

}	

.MuiOtpInput-TextField{
  height: 50px;
  width: 50px;
}

.thumb-hover-effect {
  width: 54px;
  height: 54px;
  cursor: pointer;
  padding: 4px;
  transition: transform 0.3s ease; /* This will animate the transform property over 0.3 seconds */
}

.thumb-hover-effect:hover {
  transform: scale(1.1); /* This will scale the image up to 110% of its original size on hover */
}

@keyframes shineAnimation {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}

/* CSS file */
.box-shine:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: -50%; /* Start from the left */
  bottom: 0;
  width: 50%; /* Width of the shine effect */
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8), transparent);
  animation: shineAnimation 0.4s ease;
  pointer-events: none; /* Make sure the shine effect doesn't interfere with click events */
}

.scrollbar-space {  
  padding-right: 8px; /* Same value as margin-right to reserve space for scrollbar */
  overflow-y: auto; /* Enables scrolling */
}

.profile-card-animation {
	background: linear-gradient(-45deg, #1aaf8c, #1b8fb9, #1b8fb9, #1aaf8c);
	background-size: 300% 300%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}