/* Wrapper div for the chat container */
.chat-container {
    display: flex;
    flex-direction: column;
    max-width: 720px;

    margin: auto;
    margin-bottom: 8em;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  /* Default icon container style - hidden */
.icon-container {
  display: none;
  background: white;
  padding: 5px;
  border-radius: 50%;
  /* Add more styling as needed */
}

/* Hover state for the card */
.question-card:hover .overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 16px;
  background: rgba(0, 0, 0, 0.1); /* Adjust opacity as needed */
  display: flex;
  justify-content:flex-end;
  align-items:end;
  padding-right: 10px;
}

.question-card:hover .icon-container {
  display: flex; /* or 'display: block;' depending on your needs */
}

  
  /* Chat messages container */
  .message-container {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 8em;
  }
  
  /* Individual message */
  .message {
    display: flex;
    margin-bottom: 10px;
  }
  
  /* Me label for user messages */
  .me-label {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }
  
  /* Bot label for assistant messages */
  .bot-label {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }
  
  /* Regular message text */
  .message-text {
    font-size: 14px;
  }
  
  /* Input container at the bottom */
  .input-container {
    display: flex;
    width: 640px;
    gap: 16px;
    position: fixed;
    bottom: 40px;
    align-items: center;
  }
  
  /* Text input field */
  .input-field {
    flex: 1;
    height: 40px;
    padding: 10px;
    font-size: 14px;
  }
  
  /* Send button */
  .send-button {
    height: 40px;
    padding: 0 20px;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  
  /* Scrollbar styling for the chat messages container */
  .message-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .message-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  
  .message-container::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  /* Set a minimum height for the chat messages container */
  .message-container {
    min-height: 200px;
  }